<template>
  <div class="container">
    <p class="text-danger mb-[20px] text-sm leading-normal">* 如進度條用於展示事件行銷的達成進度，請務必確認事件行銷設定的規則與相關數值，以符合進度條呈現的資訊</p>
    <el-form-item label="進度條資源來源" required>
      <template slot="label">
        <FormItemTooltipLabel label="進度條資源來源">
          進度條百分比將依所選資料來源的數據而改變
        </FormItemTooltipLabel>
      </template>
      <div class="grid grid-cols-2" style="gap: 8px;">
        <el-form-item prop="content.progressBarSettings.source" class="mb-none">
          <ElInputWrapper>
            <el-select
              v-model="syncForm.content.progressBarSettings.source"
              style="width: 100%"
              clearable
              @change="syncForm.content.progressBarSettings.countBy = null"
            >
              <el-option v-for="option in toSelectOptions(dataSourcesConfig)" :key="option.value" :label="option.label" :value="option.value" />
            </el-select>
          </ElInputWrapper>
        </el-form-item>

        <el-form-item prop="content.progressBarSettings.countBy" class="mb-none">
          <ElInputWrapper>
            <el-select v-model="syncForm.content.progressBarSettings.countBy" style="width: 100%" clearable>
              <el-option v-for="option in toSelectOptions(displaySourceKeyOptions(dataSourceKeysConfig)) " :key="option.value" :label="option.label" :value="option.value" />
            </el-select>
          </ElInputWrapper>
        </el-form-item>
      </div>
    </el-form-item>

    <el-form-item label="資料擷取時間區段">
      <div>
        <el-checkbox v-model="syncForm.content.progressBarSettings.syncPublishDate" @change="onSyncPublishDate">如同公開期間</el-checkbox>
        <p v-show="!syncForm.publishedStartAt">請先選擇公開期間</p>
        <p v-show="syncForm.publishedStartAt && syncForm.content.progressBarSettings.syncPublishDate">{{ displayDefaultDateTime }}</p>

        <div v-show="syncForm.publishedStartAt && !syncForm.content.progressBarSettings.syncPublishDate" class="flex w-full gap-[8px]">
          <el-form-item class="mb-none" prop="content.activityStartAt">
            <el-date-picker
              v-model="syncForm.content.progressBarSettings.startAt"
              type="datetime"
              placeholder="開始時間"
            />
          </el-form-item>
          <p>至</p>
          <el-form-item class="mb-none" prop="content.activityEndAt">
            <el-date-picker
              v-model="syncForm.content.progressBarSettings.endAt"
              type="datetime"
              placeholder="結束時間 (未填則為不限期)"
            />
          </el-form-item>
        </div>
      </div>
    </el-form-item>
    <el-divider />

    <el-form-item prop="content.progressBarSettings.title">
      <template #label>進度條標題<span class="text-gray-80 text-sm font-medium">（未填則不顯示標題 ）</span></template>
      <ElInputWrapper>
        <el-input v-model="syncForm.content.progressBarSettings.title" clearable style="width: 100%" placeholder="請輸入" />
      </ElInputWrapper>
    </el-form-item>
    <el-form-item prop="content.progressBarSettings.goalCount">
      <template #label>進度條 100% 顯示的數值<span class="text-gray-80 text-sm font-medium">（0% 的數值為 0 ）</span></template>
      <ElInputWrapper>
        <el-input v-model="syncForm.content.progressBarSettings.goalCount" style="width: 100%" placeholder="請輸入數值" clearable>
          <template #suffix> {{ displayCountUnit }}</template>
        </el-input>
      </ElInputWrapper>
    </el-form-item>
    <el-form-item label="進度條下方顯示資料來源與資料擷取時間區段">
      <el-switch
        v-model="syncForm.content.progressBarSettings.displayTimeRange"
        inactive-text="隱藏"
        active-text="顯示"
      />
    </el-form-item>
    <el-form-item label="進度條樣式預覽">
      <div class="preview-area">
        <CustomProgressPreview
          :title="syncForm.content.progressBarSettings.title"
          :showInfo="syncForm.content.progressBarSettings.displayTimeRange"
          :max-value="syncForm.content.progressBarSettings.goalCount"
        >
          <template #currentText>
            <div class="flex text-sm items-baseline" style="gap: 5px">
              <span>累計消費</span>
              <span class="font-bold text-danger text-sub">0</span>
              <span>{{ displayCountUnit }}</span>
            </div>
          </template>
        </CustomProgressPreview>
      </div>
    </el-form-item>
  </div>
</template>

<script>
import { computed, defineComponent, watch, onMounted } from 'vue'
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import CustomProgressPreview from './CustomProgressPreview.vue'
import { dataSourcesConfig, dataSourceKeysConfig } from '@/config/bulletin'
import { useVModel } from '@vueuse/core'
import { omit, get, isNumber } from 'lodash'
import { formatDate, isInvalidDate } from '@/utils/date'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'CustomProgressEditBlock',
  components: { ElInputWrapper, CustomProgressPreview, FormItemTooltipLabel },
  props: {
    formData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const syncForm = useVModel(props, 'formData', emit)
    const { checkActionList } = usePermissions()

    const toSelectOptions = computed(() => {
      return (data) => {
        const options = []
        for (const key in data) {
          const item = data[key]
          if (item.featureKeys) {
            if (checkActionList(item.featureKeys)) {
              options.push({
                label: item.label,
                value: item.value,
              })
            }
          } else {
            options.push({
              label: item.label,
              value: item.value,
            })
          }
        }
        return options
      }
    })

    const displaySourceKeyOptions = computed(() => {
      return (options) => {
        const omitList = []
        if (get(syncForm.value, 'content.progressBarSettings.source') === dataSourcesConfig.MEMBER_EXTERNAL_TRANSACTION.value) {
          omitList.push(dataSourceKeysConfig.itemLength.value)
        }

        return omit(options, omitList)
      }
    })

    const onSyncPublishDate = (toggle) => {
      console.log()
      if (!toggle) return
      syncForm.value.content.progressBarSettings.startAt = syncForm.value.publishedStartAt
      syncForm.value.content.progressBarSettings.endAt = syncForm.value.publishedEndAt
    }
    const displayDefaultDateTime = computed(() => {
      const start = formatDate(get(syncForm.value, 'publishedStartAt'), 'YYYY-MM-DD HH:mm')
      const end = formatDate(get(syncForm.value, 'publishedEndAt'), 'YYYY-MM-DD HH:mm')
      return `${start} ~ ${isInvalidDate(end) ? '不限期' : end}`
    })

    watch(() => props.formData.publishedStartAt, (newDate) => {
      const syncDate = get(syncForm.value, 'content.progressBarSettings.syncPublishDate')
      const startAt = get(syncForm.value, 'content.progressBarSettings.startAt')
      if (syncDate) {
        syncForm.value.content.progressBarSettings.startAt = newDate
      }
    })

    watch(() => props.formData.publishedEndAt, (newDate) => {
      const syncDate = get(syncForm.value, 'content.progressBarSettings.syncPublishDate')
      const endAt = get(syncForm.value, 'content.progressBarSettings.endAt')
      if (syncDate) {
        syncForm.value.content.progressBarSettings.endAt = newDate
      }
    })

    const displayCountUnit = computed(() => {
      if (syncForm.value.content.progressBarSettings.countBy === dataSourceKeysConfig.itemLength.value) {
        return '件'
      }
      return '元'
    })
    onMounted(() => {
      if (syncForm.value.content.progressBarSettings.syncPublishDate) {
        syncForm.value.content.progressBarSettings.startAt = syncForm.value.publishedStartAt
        syncForm.value.content.progressBarSettings.endAt = syncForm.value.publishedEndAt
      }
    })

    return { dataSourcesConfig, dataSourceKeysConfig, syncForm, toSelectOptions, displayDefaultDateTime, displayCountUnit, isNumber, displaySourceKeyOptions, onSyncPublishDate }
  },
})
</script>

<style lang="postcss" scoped>
.container {
  @apply bg-gray-20 rounded-[8px] px-[20px] py-[20px] max-w-[560px]
}

.preview-area {
    @apply bg-white w-full py-[28px];
}

::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner {
  @apply w-full;
}
.el-form-item {
  @apply w-full mb-[20px];
}
.mb-none {
  @apply mb-0;
}
</style>
