<template>
  <div
    class="w-full bg-gray-200 relative overflow-hidden progressbar-container"
    :class="[{ 'rounded-full': rounded }, { indeterminate: indeterminate }]"
    :style="{
      backgroundColor: computedColor.bg,
      borderColor: computedColor.border,
    }"
  >
    <div
      class="h-full progressbar"
      :class="[
        { 'absolute top-0': indeterminate },
        { 'rounded-full': rounded },
      ]"
      role="progressbar"
      :style="{
        width: `${percentage || 10}%`,
        backgroundColor: computedColor.bar,
      }"
      :aria-valuenow="percentage"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <span class="current-progress">
        <slot v-if="percentage" />
        <span v-else class="text-xs relative text-white"> 0% </span>
      </span>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent } from 'vue'
import { useColor } from '@/use/useColor'

export default defineComponent({
  name: 'BaseProgress',
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'red',
    },
    percentage: {
      type: Number,
      default: 0,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    indeterminate: Boolean,
  },
  setup (props, { emit }) {
    const { getCssVar, hexToRGBA } = useColor()
    const containerBg = computed(() => {
      const color = getCssVar('danger')
      return hexToRGBA(color, 0.1)
    })

    const computedColor = computed(() => {
      if (props.disabled) {
        const gray = getCssVar('gray-1')
        return {
          border: 'var(--gray-1)',
          bar: 'var(--gray-1)',
          bg: hexToRGBA(gray, 0.1),
        }
      }
      return {
        border: props.color,
        bar: props.color,
        bg: containerBg.value,
      }
    })
    return { containerBg, computedColor }
  },
})
</script>
<style scoped lang="postcss">
@keyframes progress-indeterminate {
  0% {
    width: 30%;
    left: -40%;
  }

  60% {
    left: 100%;
    width: 100%;
  }

  to {
    left: 100%;
    width: 0;
  }
}

.progressbar-container {
  @apply border-solid border-[1px];
  /* @apply bg-opacity-[20%] bg-alert-primary; */
  /* @apply min-h-[20px]; */
}

.progressbar {
  transition: width 0.25s ease;
  background-color: var(--alert-primary);
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    rgba(255, 255, 255, 0.3) 10px,
    rgba(255, 255, 255, 0.3) 20px
  );
}

.indeterminate .progressbar {
  animation: progress-indeterminate 1.4s ease infinite;
}

.current-progress {
  @apply flex items-center h-full justify-end px-[6.5px] py-[3px];
}
</style>
