<template>
  <div class="progress-preview">
    <p class="title">{{ title }}</p>
    <base-progress :percentage="0">
      <span class="text-xs text-white w-full flex justify-end pr-1">30%</span>
    </base-progress>
    <div class="grid grid-cols-3" style="line-height: 30px;">
      <span class="text-danger text-sm" style="justify-self: start;">0</span>
      <div style="justify-self: center;">
        <slot name="currentText" />
      </div>
      <span class="text-danger text-sm" style="justify-self: end;">{{ maxValue && isNumber(Number(maxValue)) ? Number(maxValue).toLocaleString() : '' }}</span>
    </div>
    <div v-if="showInfo" class="flex items-center justify-center" style="gap: 4px;">
      <MaterialIcon color="#AEAEAE">info_outline</MaterialIcon>
      <p class="text-sm" style="color: #AEAEAE">資料節錄 20yy/mm/dd - 20yy/mm/dd 的消費記錄</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import BaseProgress from '@/components/BaseProgress.vue'
import MaterialIcon from '@/components/MaterialIcon.vue'
import { isNumber } from 'lodash'

export default defineComponent({
  name: 'CustomProgressPreview',
  components: { BaseProgress, MaterialIcon },
  props: {
    title: String,
    showInfo: Boolean,
    maxValue: [Number, String],
  },
  setup (props) {
    return { isNumber }
  },
})
</script>

<style lang="postcss" scoped>
.progress-preview {
    @apply rounded-[8px] px-[20px] py-[12px];
    @apply bg-white w-[fit-content] m-auto min-w-[368.23px];
    box-shadow: 0px 0px 20px 0px #0000001A;

}
.title {
  @apply text-[15px] font-bold text-center text-[#E55353] tracking-[1px] mb-[12px] leading-normal min-h-[22.5px];
}

</style>
