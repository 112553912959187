export const useColor = () => {
  const getCssVar = (varName) => {
    return getComputedStyle(document.documentElement).getPropertyValue(
      `--${varName}`,
    )
  }

  const hexToRGBA = (hex, alpha) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

    var r = parseInt(result[1], 16)
    var g = parseInt(result[2], 16)
    var b = parseInt(result[3], 16)

    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
  }

  return { getCssVar, hexToRGBA }
}
