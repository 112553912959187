<template>
  <div class="side-highlight-container">
    <p class="card-title">基本設定</p>
    <el-form-item>
      <template slot="label">
        <FormItemTooltipLabel label="是否為會員中心佈告欄的資訊頁">
          請於「會員中心佈告欄」設定
        </FormItemTooltipLabel>
      </template>
      <p>{{ syncForm.binded ? '是' : '否'}}</p>
    </el-form-item>

    <el-form-item required>
      <template slot="label">
        <FormItemTooltipLabel label="公開期間">
          佈告資訊頁可供會員瀏覽的期間。<br>
          此時間不會顯示在佈告資訊頁
        </FormItemTooltipLabel>
      </template>
      <div class="flex w-full gap-[8px] max-w-[560px]">
        <el-form-item prop="publishedStartAt">
          <el-date-picker
            v-model="syncForm.publishedStartAt"
            type="datetime"
            placeholder="開始時間"
            @change="onPublishedStartAtChange"
            @clear="onPublishedStartAtClear"
          />
        </el-form-item>
        <p>至</p>
        <el-form-item prop="publishedEndAt">
          <el-date-picker
            v-model="syncForm.publishedEndAt"
            type="datetime"
            placeholder="結束時間 (未填則為不限期)"
            @change="onPublishedEndAtChange"
          />
        </el-form-item>
      </div>
    </el-form-item>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useVModel } from '@vueuse/core'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { get } from 'lodash'

export default defineComponent({
  name: 'BulletinDetailInfoBlock',
  components: { FormItemTooltipLabel },
  props: {
    formData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const syncForm = useVModel(props, 'formData', emit)
    const onPublishedStartAtChange = (newDate) => {
      if (newDate === null) {
        syncForm.value.content.activityStartAt = null
      }
      if (get(syncForm.value, 'content.syncPublishDate')) {
        syncForm.value.content.activityStartAt = newDate
      }
      if (get(syncForm.value, 'content.enabledProgressBar') && get(syncForm.value, 'content.progressBarSettings.syncPublishDate')) {
        syncForm.value.content.progressBarSettings.startAt = newDate
      }
    }
    const onPublishedEndAtChange = (newDate) => {
      if (get(syncForm.value, 'content.syncPublishDate')) {
        syncForm.value.content.activityEndAt = newDate
      }
      if (get(syncForm.value, 'content.enabledProgressBar') && get(syncForm.value, 'content.progressBarSettings.syncPublishDate')) {
        syncForm.value.content.progressBarSettings.endAt = newDate
      }
    }

    const onPublishedStartAtClear = () => {
      console.log('on clear')
      syncForm.value.content.activityStartAt = null
    }
    return { syncForm, onPublishedStartAtChange, onPublishedEndAtChange, onPublishedStartAtClear }
  },
})
</script>

<style lang="postcss" scoped>
.el-form-item, .el-date-editor.el-input {
  @apply w-full
}
</style>
