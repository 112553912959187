<template>
  <div class="">
    <div class="content-block-list">
      <div v-for="(block, idx) in contentList" :key="`${block.type}-${idx}`">
        <ImageBlockEditBlock
          v-if="block.type === 'image'"
          :data.sync="contentList[idx]"
          @remove="removeBlock(idx)"
          @upLayer="moveData(idx, 'up')"
          @downLayer="moveData(idx, 'down')"
        />
        <ContentBlockEditBlock
          v-if="block.type === 'html'"
          :data.sync="contentList[idx]"
          @remove="removeBlock(idx)"
          @upLayer="moveData(idx, 'up')"
          @downLayer="moveData(idx, 'down')"
        />
      </div>
    </div>
    <div class="flex items-center">
      <AddButton content="新增文字" @click="addBlock('html')" />
      <AddButton content="新增圖片" @click="addBlock('image')" />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, watch } from 'vue'
import ContentBlockEditBlock from '@/components/ContentBlockEditBlock.vue'
import ImageBlockEditBlock from '@/components/ImageBlockEditBlock.vue'
import AddButton from '@/components/Button/AddButton.vue'
import { useImageTextContent } from '@/use/useImageTextContent'

export default defineComponent({
  name: 'ImageContentEditArea',
  components: { ContentBlockEditBlock, ImageBlockEditBlock, AddButton },
  props: {
    contentData: { type: Array, default: () => [] },
  },
  setup (props, { emit }) {
    const { contentList, addBlock, removeBlock, moveData } = useImageTextContent()

    watch(contentList, () => {
      emit('change', contentList.value)
    }, { deep: true })

    const unWatch = watch(() => props.contentData, () => {
      contentList.value = props.contentData
      unWatch()
    }, { deep: true })

    onMounted(() => {
      contentList.value = props.contentData
    })

    return { contentList, addBlock, removeBlock, moveData }
  },
})
</script>

<style lang="postcss" scoped>
.content-block-list {
    @apply flex flex-col gap-[20px] mb-[12px];
}
</style>
