var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-full bg-gray-200 relative overflow-hidden progressbar-container",class:[{ 'rounded-full': _vm.rounded }, { indeterminate: _vm.indeterminate }],style:({
    backgroundColor: _vm.computedColor.bg,
    borderColor: _vm.computedColor.border,
  })},[_c('div',{staticClass:"h-full progressbar",class:[
      { 'absolute top-0': _vm.indeterminate },
      { 'rounded-full': _vm.rounded },
    ],style:({
      width: `${_vm.percentage || 10}%`,
      backgroundColor: _vm.computedColor.bar,
    }),attrs:{"role":"progressbar","aria-valuenow":_vm.percentage,"aria-valuemin":"0","aria-valuemax":"100"}},[_c('span',{staticClass:"current-progress"},[(_vm.percentage)?_vm._t("default"):_c('span',{staticClass:"text-xs relative text-white"},[_vm._v(" 0% ")])],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }