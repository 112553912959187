<template>
  <quillEditor v-model="syncValue" :options="computedEditorOptions" />
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useEditor } from '@/use/editor'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { useVModel } from '@vueuse/core'

export default defineComponent({
  name: 'TextEditor',
  components: { quillEditor },
  props: {
    value: String,
    options: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const syncValue = useVModel(props, 'value', emit)
    const { editorOption } = useEditor()

    const computedEditorOptions = computed(() => {
      return {
        ...editorOption,
        ...props.options,
      }
    })

    return { syncValue, computedEditorOptions }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .ql-editor {
  @apply min-h-[100px];
}
</style>
